import React from 'react';
import { graphql } from 'gatsby';
import Masonry from 'react-masonry-component';
import { view } from 'react-easy-state';

import Layout from '../components/layout';
import Category, { CardWrapper } from '../components/category';
import main from '../stores';

@view
class IndexPage extends React.Component {
  componentDidUpdate() {
    if (main.masonry) main.masonry.layout();
  }

  render() {
    const { allAweCategory } = this.props.data;
    const content = allAweCategory.edges.map(edge => edge.node);
    const { showSummary, showInfo } = main;

    return (
      <Layout>
        <Masonry
          ref={c => { main.masonry = main.masonry || c.masonry; }}
          css={{ margin: '0 -0.5rem', zIndex: 10 }}
        >
          {content.map(category => (
            <Category key={category.id} category={category} />
          ))}

          <CardWrapper>
            <div css={tw`px-4 py-4`}>
              <p>
                <a
                  href="https://creativecommons.org/publicdomain/zero/1.0/"
                  rel="nofollow"
                >
                  <img
                    src="https://camo.githubusercontent.com/60561947585c982aee67ed3e3b25388184cc0aa3/687474703a2f2f6d6972726f72732e6372656174697665636f6d6d6f6e732e6f72672f70726573736b69742f627574746f6e732f38387833312f7376672f63632d7a65726f2e737667"
                    alt="CC0"
                    data-canonical-src="http://mirrors.creativecommons.org/presskit/buttons/88x31/svg/cc-zero.svg"
                    css={tw`max-w-full mb-2 block`}
                  />
                </a>
                To the extent possible under law,{' '}
                <a href="https://sindresorhus.com/">Sindre Sorhus</a> has waived
                all copyright and related or neighboring rights to this work.
              </p>
              <p>This Awesome List viewer/aggregator is made by Wan Saleh.</p>
            </div>
          </CardWrapper>
        </Masonry>
      </Layout>
    );
  }
}

export default IndexPage;

export const query = graphql`
  query IndexQuery {
    allAweCategory {
      edges {
        node {
          id
          title
          slug
          totalRepos
          repos {
            slug
            title
            url
            summary
            homepage
            updatedAt
            pushedAt
            stars
            watchers
            notFound
            deprecated
            category {
              slug
              title
            }
            child {
              slug
              title
              url
              summary
              pushedAt
              stars
              watchers
              notFound
              deprecated
            }
          }
        }
      }
    }
  }
`;
